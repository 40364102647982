import React from 'react'
import PropTypes from 'prop-types'

import pic01 from '../images/pic01.jpg'
import pic02 from '../images/pic02.jpg'
import pic03 from '../images/pic03.jpg'

class Main extends React.Component {
  render() {
    let close = (
      <div
        className="close"
        onClick={() => {
          this.props.onCloseArticle()
        }}
      />
    )

    return (
      <div
        ref={this.props.setWrapperRef}
        id="main"
        style={this.props.timeout ? { display: 'flex' } : { display: 'none' }}
      >
        <article
          id="intro"
          className={`${this.props.article === 'intro' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Intro</h2>
          <span className="image main">
            <img src={pic01} alt="" />
          </span>
          <p>
            Aenean ornare velit lacus, ac varius enim ullamcorper eu. Proin
            aliquam facilisis ante interdum congue. Integer mollis, nisl amet
            convallis, porttitor magna ullamcorper, amet egestas mauris. Ut
            magna finibus nisi nec lacinia. Nam maximus erat id euismod egestas.
            By the way, check out my <a href="#work">awesome work</a>.
          </p>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis
            dapibus rutrum facilisis. Class aptent taciti sociosqu ad litora
            torquent per conubia nostra, per inceptos himenaeos. Etiam tristique
            libero eu nibh porttitor fermentum. Nullam venenatis erat id
            vehicula viverra. Nunc ultrices eros ut ultricies condimentum.
            Mauris risus lacus, blandit sit amet venenatis non, bibendum vitae
            dolor. Nunc lorem mauris, fringilla in aliquam at, euismod in
            lectus. Pellentesque habitant morbi tristique senectus et netus et
            malesuada fames ac turpis egestas. In non lorem sit amet elit
            placerat maximus. Pellentesque aliquam maximus risus, vel sed
            vehicula.
          </p>
          {close}
        </article>

        <article
          id="imprint"
          className={`${this.props.article === 'imprint' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Imprint</h2>
          <p>
            <br />
            Company: geekazoid e.U.
            <br />
            Owner: Dipl.-Ing. Andreas Bretschneider
            <br />
            Address: Billrothweg 1, 3400 Klosterneuburg, Austria
            <br />
            Phone: <a href="tel:00436769714179">+43 676 971 4 179</a>
            <br />
            Email:{' '}
            <a href="mailto:bretschneider.andreas@gmail.com">
              bretschneider.andreas@gmail.com
            </a>
            <br />
            VAT Number: ATU66866028
            <br />
            Company Register Number: FN 371939 w
            <br />
            Company Register Court: Landesgericht Korneuburg
            <br />
            Commerce Chamber Membership: Wirtschaftskammer Österreich
          </p>
          {close}
        </article>
        {/* <article
          id="projects"
          className={`${this.props.article === 'projects' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Personal projects</h2>
          <p>
            <a href="https://www.traceforce.live">TraceForce App</a>
            <br />
            TraceForce is a tracker and maps app designed for milsim, airsoft
            and scenario paintball. It aims to provide a nextgen geospatial
            infrastructure to increase efficiency and situational awareness
            through position and information sharing.
          </p>
          <p>
            <a href="https://www.airsoftmoments.com">Airsoft Moments</a>
            <br />
            Doing photography in airsoft, "where to upload and share" was a
            prevalent question. Therefore I created a multi photographer website
            with wordpress, serving as a photography platform for the community.
          </p>
          {close}
        </article> */}
      </div>
    )
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}

export default Main
